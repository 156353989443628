import React, { useRef } from 'react';
import { extend, useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three';
import { useScroll } from '@react-three/drei';


const isClient = typeof window !== "undefined";
  const isMobile = isClient ? window.innerWidth <= 1400 : false; 

const vertexShader = `
varying vec2 vUv;

void main() {
  vUv = uv;
  gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}
`;

const fragmentShader = `
precision mediump float;

uniform vec2 iResolution;
uniform float iTime;
uniform float fattore;
varying vec2 vUv;

vec2 random2(vec2 st) {
  st = vec2(dot(st, vec2(127.1,311.7)),
            dot(st, vec2(269.5,183.3)));
  return -1.0 + 2.0 * fract(sin(st) * 43758.5453123);
}

float noise(vec2 st) {
  vec2 i = floor(st);
  vec2 f = fract(st);
  vec2 u = f * f * (3.0 - 2.0 * f);
  return mix(mix(dot(random2(i + vec2(0.0,0.0)), f - vec2(0.0,0.0)),
                   dot(random2(i + vec2(1.0,0.0)), f - vec2(1.0,0.0)), u.x),
              mix(dot(random2(i + vec2(0.0,1.0)), f - vec2(0.0,1.0)),
                   dot(random2(i + vec2(1.0,1.0)), f - vec2(1.0,1.0)), u.x), u.y);
}

float circle(vec2 st, vec2 center, float radius) {
  return smoothstep(1., 1.-0.025, distance(st, center) / radius);
}

float ring(vec2 st, vec2 center, float radius) {
  return circle(st, center, radius) - circle(st, center, radius - 0.020);
}

void main() {
  vec2 st = (fattore * vUv * iResolution.xy - iResolution.xy) / iResolution.y;
  vec3 color = vec3(0.5);

  float r = 0.67, a = atan(st.y, st.x), noiseA = a + iTime;
  vec2 nPos = vec2(cos(noiseA), sin(noiseA));
  float n = noise(nPos), n2 = noise(nPos + iTime);
  r += sin(a*10.) * n * .18;
  r += sin(a*30.) * n2 * .08;

  float pct = ring(st, vec2(0.), r); 
  color = vec3(0.6, 0.4, 0.9) * pct + vec3(1.0, 1.0, 0.0) * pct * n;

  // Impostazione del valore alpha in base a 'pct'
  // Se pct è 0, il pixel sarà completamente trasparente, altrimenti sarà opaco
  float alpha = smoothstep(0.0, 0.1, pct);
  gl_FragColor = vec4(color, alpha);
}



`;

class CustomShaderMaterial extends THREE.ShaderMaterial {
  constructor() {
    super({
      vertexShader,
      fragmentShader,
      uniforms: {
        iTime: { value: 0 },
        iResolution: { value: new THREE.Vector2() },
        fattore: { value: isMobile? 2.0: 2.0 },
      },
    });
  }
}

extend({ CustomShaderMaterial });

export function ShaderOcean() {
  const materialRef = useRef();
  const { size } = useThree();
  const scroll = useScroll();

  useFrame(({ clock }) => {
    if (materialRef.current) {
      materialRef.current.uniforms.iTime.value = clock.getElapsedTime()/3 + scroll.offset * 30;
      materialRef.current.uniforms.iResolution.value.set(1920, 1080);
    }
  });

  return (
    <mesh>
      <planeGeometry args={isMobile? [1.92, 1.08]  :  [3.84, 2.16]} />
      <customShaderMaterial ref={materialRef} />
    </mesh>
  );
}
