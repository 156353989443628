import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import './styles.css';
import Render from './Render';

const Home = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 1000); // 3 secondi di timeout
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {loading && <div className="loader"></div>}
      <Render />
    </>
  );
};

createRoot(document.getElementById('root')).render(<Home />);