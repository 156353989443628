import * as THREE from 'three'
import { Text,  ScrollControls, Scroll, useFBO, useScroll, Html } from '@react-three/drei'
import { useFrame, useThree, useLoader, createPortal } from "@react-three/fiber"
import { React, useRef, useState, useContext, useEffect, useLayoutEffect } from 'react'


import { TextureLoader } from 'three';

import { ShaderOcean } from './ShaderOcean'

import gsap from 'gsap';


export default function App() {
  return (
    <> 
<TestoScroll/>
    </>
  )
}


function Items(props) {
  
  const isClient = typeof window !== "undefined";
  const isMobile = isClient ? window.innerWidth <= 1200 : false; 
  const viewport = useThree((state) => state.viewport)
  const fattore = viewport.width
  const [texture, texture2, texture3, texturecircular, texturebh, texturelantoki] = useLoader(TextureLoader, [
    'logo.png',
    'logos.png',
    'logoeu.png',
    'circular.png',
    'logobh.png',
    'lantoki.png'
  ]);
  
  const [hovered, setHovered] = useState(false);
  const [hovered2, setHovered2] = useState(false);
  const [hovered3, setHovered3] = useState(false);
  const [hovered4, setHovered4] = useState(false);
  const [hovered5, setHovered5] = useState(false);
  const [hoveredDown, setHoveredDown] = useState(false);
  const [hoveredApply, setHoveredApply] = useState(false);
  const [hovered6, setHovered6] = useState(false);
  const radius = Math.min(2.4, viewport.width / 2.8)
  const radiush = Math.min(2.4, viewport.height / 2.8)
  const scroll = useScroll()
  const ref = useRef()
  const ref2 = useRef()
  const ref4 = useRef()
 
  useFrame(() => {
    ref.current.position.z = isMobile ? -scroll.offset * 20 : -scroll.offset * 15;
    ref2.current.position.z = isMobile ? -scroll.offset * 20 : -scroll.offset * 15;

  });
  
  const navigateToPercentage = (percentage) => {
    const scrollElement = scroll.el;
    if (scrollElement) {
      const totalScrollHeight = scrollElement.scrollHeight - scrollElement.clientHeight;
      const newScrollPosition = totalScrollHeight * (percentage / 100);
      scrollElement.scrollTop = newScrollPosition;
    }
  };

  const NavHome = () => navigateToPercentage(isMobile? 75:81); // Vai all'inizio
  return (
<>
<ambientLight  intensity={0.5} />
<Scroll>


<group ref={ref} >
    <mesh position={[0, 0, -1]} scale={isMobile? 1+ radius: 2} >
      <planeGeometry attach="geometry" args={[1.181, 0.827]} />
      <meshStandardMaterial attach="material" color="#997ada" emissive='#997ada' emissiveIntensity={1} map={texture} transparent={true} />
    </mesh>
    </group>
<group ref={ref2} >
 <mesh position={[0, 0, -2]} ref={ref2} scale={isMobile? 1+ radius:2} >
   <planeGeometry attach="geometry" args={[1.181, 0.827]} />
   <meshStandardMaterial attach="material" map={texture2} transparent={true} color="#43659c" emissive='#43659c' emissiveIntensity={1}  />
</mesh>
</group>


{/* GRUPPO TESTO */}
  <group position={[0, -1, 0.1]} >
{/* GRUPPO ABOUT */}
  <group position={isMobile? [0, 0.15, 0]:  [0, -1.5, 0]} >

   <Text scale={0.3} position={isMobile? [0, -0.7 - radius*0.4, -0.5] : [0, 0.6, -1]} fontSize={isMobile? fattore*0.24 : 0.35 } font="./Fonts/Roboto.ttf" color="#ffffff" anchorX="center" anchorY="bottom"
   textAlign='left'
    maxWidth={isMobile? radius* 9 :  11} >JOIN NOW OUR OPEN CALL FOR FASHION DESIGNERS!  
    </Text>
    <Text scale={0.3} position={isMobile? [0, -0.7 - fattore*0.6, -0.5] : [0, -0.065, -1]} fontSize={isMobile? fattore*0.18: 0.35 } font="./Fonts/RobotoMedium.ttf" color="#ffffff" anchorX="center" anchorY="bottom"
   textAlign='left'
    maxWidth={isMobile? radius* 9 :  11} >Are you an emergent fashion designer and you believe in a sustainable future for fashion?
    Be part of a change-making journey with ARCA, where your designs can contribute to
    shaping a more inclusive and environmentally conscious fashion industry
    </Text>
    <Text scale={0.3} position={isMobile? [0, -0.7 - fattore*0.75, -0.5] : [0, -0.2, -1]} fontSize={isMobile? fattore*0.2: 0.35 } font="./Fonts/Roboto.ttf" color="#997ada" anchorX="center" anchorY="bottom"
   textAlign='left'
    maxWidth={isMobile? radius* 9 :  11} >Apply to our Open Call and join the movement!</Text>
      <Text scale={0.3} position={isMobile? [0, -0.7 - fattore*0.85, -0.5] : [0, -0.35, -1]} fontSize={isMobile? fattore*0.18: 0.35 } font="./Fonts/RobotoMedium.ttf" color="#ffffff" anchorX="center" anchorY="bottom"
   textAlign='left'
    maxWidth={isMobile? radius* 9 :  11} >(Deadline: 31 March EXTENDED )</Text>
{/* LISTA 1 */}
<group position={isMobile? [0,0,0]:[-1.65,0,0]} >
<Text scale={0.3} position={isMobile? [-fattore*0.45, -0.7 - fattore*1.05, -0.5] : [0, -0.6, -1]} fillOpacity={0.9} fontSize={isMobile? fattore*0.28: 0.35 } font="./Fonts/Roboto.ttf" color="#997ada" anchorX="left" anchorY="bottom"
   textAlign='left'
    maxWidth={isMobile? radius* 9 :  11} >Our Design Challenge:</Text>
    <Text scale={0.3} position={isMobile? [-radius * 1.5 +0.1, -0.7 - fattore*1.25, -0.5] : [0, -0.75, -1]}  fontSize={isMobile? fattore*0.18: 0.35 } font="./Fonts/RobotoMedium.ttf" color="#ffffff" anchorX="left" anchorY="bottom" textAlign='left'
    maxWidth={isMobile? radius* 9 :  11} >• Create a 6-piece non-binary capsule collection.
    </Text>
    <Text scale={0.3} position={isMobile? [-radius * 1.5 +0.1, -0.7 - fattore*1.4, -0.5] : [0, -0.9, -1]} fontSize={isMobile? fattore*0.18: 0.35 } font="./Fonts/RobotoMedium.ttf" color="#ffffff" anchorX="left" anchorY="bottom" textAlign='left'
    maxWidth={isMobile? radius* 9 :  11} >• Focus on circular design, multifunctionality, modularity.
    </Text>
    <Text scale={0.3} position={isMobile? [-radius * 1.5 +0.1, -0.7 - fattore*1.55, -0.5] : [0, -1.05, -1]} fontSize={isMobile? fattore*0.18: 0.35 } font="./Fonts/RobotoMedium.ttf" color="#ffffff" anchorX="left" anchorY="bottom" textAlign='left'
    maxWidth={isMobile? radius* 9 :  11} >• Balance originality and wearability, inspired by European city styles.
    </Text>
    </group>
{/* LISTA 2 */}
    <group position={isMobile? [0,0,0]:[-1.65,0,0]} >
    <Text scale={0.3} position={isMobile? [-fattore*0.45, -0.7 - fattore*1.8, -0.5] : [0, -1.25, -1]} fillOpacity={0.9} fontSize={isMobile? fattore*0.28: 0.35 }  font="./Fonts/Roboto.ttf" color="#997ada" anchorX="left" anchorY="bottom"
   textAlign='left'
    maxWidth={isMobile? radius* 9 :  11} >Who We're Looking For:</Text>
    <Text scale={0.3} position={isMobile? [-radius * 1.5 +0.1, -0.7 - fattore*2, -0.5] : [0, -1.40, -1]} fontSize={isMobile? fattore*0.18: 0.35 } font="./Fonts/RobotoMedium.ttf" color="#ffffff" anchorX="left" anchorY="bottom" textAlign='left'
    maxWidth={isMobile? radius* 9 :  11} >• Emerging creatives in sustainable fashion, 0-5 years into their career.
    </Text>
    <Text scale={0.3} position={isMobile? [-radius * 1.5 +0.1, -0.7 - fattore*2.20, -0.5] : [0, -1.65, -1]} fontSize={isMobile? fattore*0.18: 0.35 } font="./Fonts/RobotoMedium.ttf" color="#ffffff" anchorX="left" anchorY="bottom" textAlign='left'
    maxWidth={isMobile? radius* 9 :  11} >• Fresh graduates and new practitioners, all genders, ages, and nationalities.
    </Text>
    <Text scale={0.3} position={isMobile? [-radius * 1.5 +0.1, -0.7 - fattore*2.35, -0.5] : [0, -1.8, -1]} fontSize={isMobile? fattore*0.18: 0.35 } font="./Fonts/RobotoMedium.ttf" color="#ffffff" anchorX="left" anchorY="bottom" textAlign='left'
    maxWidth={isMobile? radius* 9 :  11} >• Must have strong English skills for collaboration.
    </Text>
    <Text scale={0.3} position={isMobile? [-radius * 1.5 +0.1, -0.7 - fattore*2.5, -0.5] : [0, -1.95, -1]} fontSize={isMobile? fattore*0.18: 0.35 } font="./Fonts/RobotoMedium.ttf" color="#ffffff" anchorX="left" anchorY="bottom" textAlign='left'
    maxWidth={isMobile? radius* 9 :  11} >• Residency in/close to Bologna, Berlin, Barcelona required.
    </Text>
    </group>

{/* LISTA 3 */}
<group position={isMobile? [0,0,0]:[-1.65,0,0]} >
    <Text scale={0.3} position={isMobile? [-fattore*0.45, -0.7 - fattore*2.75, -0.5] : [0, -2.15, -1]} fillOpacity={0.9} fontSize={isMobile? fattore*0.28: 0.35 }  font="./Fonts/Roboto.ttf" color="#997ada" anchorX="left" anchorY="bottom"
   textAlign='left'
    maxWidth={isMobile? radius* 9 :  11} >Rewards:</Text>
    <Text scale={0.3} position={isMobile? [-radius * 1.5 +0.1, -0.7 - fattore*3, -0.5] : [0, -2.45, -1]} fontSize={isMobile? fattore*0.18: 0.35 } font="./Fonts/RobotoMedium.ttf" color="#ffffff" anchorX="left" anchorY="bottom" textAlign='left'
    maxWidth={isMobile? radius* 9 :  11} >• 2,400€/3000€ individual grant for the selected designers (check PDF guidelines for
      details)
    </Text>
    <Text scale={0.3} position={isMobile? [-radius * 1.5 +0.1, -0.7 - fattore*3.15, -0.5] : [0, -2.6, -1]} fontSize={isMobile? fattore*0.18: 0.35 } font="./Fonts/RobotoMedium.ttf" color="#ffffff" anchorX="left" anchorY="bottom" textAlign='left'
    maxWidth={isMobile? radius* 9 :  11} >• A 6-weeks online mentoring and co-creation programme.
    </Text>
    <Text scale={0.3} position={isMobile? [-radius * 1.5 +0.1, -0.7 - fattore*3.35, -0.5] : [0, -2.85, -1]} fontSize={isMobile? fattore*0.18: 0.35 } font="./Fonts/RobotoMedium.ttf" color="#ffffff" anchorX="left" anchorY="bottom" textAlign='left'
    maxWidth={isMobile? radius* 9 :  11} >• Online & offline exposure through: digital features and exhibition opportunity in
    Bologna.
    </Text>
    <Text scale={0.3} position={isMobile? [-radius * 1.5 +0.1, -0.7 - fattore*3.5, -0.5] : [0, -3, -1]} fontSize={isMobile? fattore*0.18: 0.35 } font="./Fonts/RobotoMedium.ttf" color="#ffffff" anchorX="left" anchorY="bottom" textAlign='left'
    maxWidth={isMobile? radius* 9 :  11} >• Cross-border artistic exchange and networking.
    </Text>
    </group>

{/* LISTA 4 */}
<group position={isMobile? [0,0,0]:[-1.65,0,0]} >
    <Text scale={0.3} position={isMobile? [-fattore*0.45, -0.7 - fattore*3.75, -0.5] : [0, -3.2, -1]} fillOpacity={0.9} fontSize={isMobile? fattore*0.28: 0.35 }  font="./Fonts/Roboto.ttf" color="#997ada" anchorX="left" anchorY="bottom"
   textAlign='left'
    maxWidth={isMobile? radius* 9 :  11} >Application Documents:</Text>
    <Text scale={0.3} position={isMobile? [-radius * 1.5 +0.1, -0.7 - fattore*4.05, -0.5] : [0, -3.5, -1]} fontSize={isMobile? fattore*0.18: 0.35 } font="./Fonts/RobotoMedium.ttf" color="#ffffff" anchorX="left" anchorY="bottom" textAlign='left'
    maxWidth={isMobile? radius* 9 :  11} >• To be send via Email: Resume, Portfolio, Personal Statement, Design Proposal
    (Check Call guidelines PDF for detailed instructions)
    </Text>
    <Text scale={0.3} position={isMobile? [-radius * 1.5 +0.1, -0.7 - fattore*4.15, -0.5] : [0, -3.65, -1]} fontSize={isMobile? fattore*0.18: 0.35 } font="./Fonts/RobotoMedium.ttf" color="#ffffff" anchorX="left" anchorY="bottom" textAlign='left'
    maxWidth={isMobile? radius* 9 :  11} >• Online Application form (link)
    </Text>
    </group>
{/* LISTA 5 */}
<group position={isMobile? [0,0,0]:[-1.65,0,0]} >
    <Text scale={0.3} position={isMobile? [-fattore*0.45, -0.7 - fattore*4.35, -0.5] : [0, -3.85, -1]}fillOpacity={0.9} fontSize={isMobile? fattore*0.28: 0.35 }  font="./Fonts/Roboto.ttf" color="#997ada" anchorX="left" anchorY="bottom"
   textAlign='left'
    maxWidth={isMobile? radius* 9 :  11} >Timeline:</Text>
    <Text scale={0.3} position={isMobile? [-radius * 1.5 +0.1, -0.7 - fattore*4.5, -0.5] : [0, -4, -1]} fontSize={isMobile? fattore*0.18: 0.35 } font="./Fonts/RobotoMedium.ttf" color="#ffffff" anchorX="left" anchorY="bottom" textAlign='left'
    maxWidth={isMobile? radius* 9 :  11} >Open Call: 7 February 2024.
    </Text>
    <Text scale={0.3} position={isMobile? [-radius * 1.5 +0.1, -0.7 - fattore*4.625, -0.5] : [0, -4.15, -1]} fontSize={isMobile? fattore*0.18: 0.35 } font="./Fonts/RobotoMedium.ttf" color="#ffffff" anchorX="left" anchorY="bottom" textAlign='left'
    maxWidth={isMobile? radius* 9 :  11} >Application Deadline: (EXTENDED) 31 March 2024.
    </Text>
    <Text scale={0.3} position={isMobile? [-radius * 1.5 +0.1, -0.7 - fattore*4.7, -0.5] : [0, -4.3, -1]} fontSize={isMobile? fattore*0.18: 0.35 } font="./Fonts/RobotoMedium.ttf" color="#ffffff" anchorX="left" anchorY="bottom" textAlign='left'
    maxWidth={isMobile? radius* 9 :  11} >Announcement: End of April 2024.
    </Text>
    <Text scale={0.3} position={isMobile? [-radius * 1.5 +0.1, -0.7 - fattore*4.8, -0.5] : [0, -4.45, -1]} fontSize={isMobile? fattore*0.18: 0.35 } font="./Fonts/RobotoMedium.ttf" color="#ffffff" anchorX="left" anchorY="bottom" textAlign='left'
    maxWidth={isMobile? radius* 9 :  11} >Programme: May - mid June 2024.
    </Text>
    </group>

    <Text scale={0.4} position={isMobile? [0, -0.7 - fattore*5.2, -0.5] : [0, -4.8, -1]} fontSize={isMobile? fattore*0.24 : 0.35 } font="./Fonts/Roboto.ttf" color="#ffffff" anchorX="center" anchorY="bottom"
   textAlign='center'
    maxWidth={isMobile? radius* 7 :  11} >Read Call guidelines PDF for full details</Text>

<group onPointerOver={() => setHoveredDown(true)}
    onPointerOut={() => setHoveredDown(false)}
    onClick={NavHome}  scale={isMobile? fattore*0.8:1} position={isMobile? [0, -0.7 - fattore*5.4, -0.5] : [0, -5.1, -1]} >
    <Text scale={ 0.3} position={isMobile? [0, -0.05, 0]:[0,-0.065,0]}  fontSize={isMobile? 0.28 : 0.35 } font="./Fonts/Roboto.ttf" color={hoveredDown ? "#72c163" : "#ffffff"} anchorX="center" anchorY="bottom"
   textAlign='center'
   maxWidth={isMobile? radius* 9 :  15} >DOWNLOAD
    </Text>
    <Cover scale={isMobile ? [1.3, 1.2, 1] : [1.6, 1.4, 1]}  />
    </group>

    <group 
 onPointerOver={() => setHoveredApply(true)}
 onPointerOut={() => setHoveredApply(false)}
 onClick={() => window.open('https://forms.gle/86bUfbnJpJcYGPn6A', 'none')} scale={isMobile? fattore*0.8:1} position={isMobile? [0, -0.7 - fattore*5.65, -0.5] : [0, -5.5, -1]} > 
    <Text  scale={0.3} position={isMobile? [0, -0.05, 0]:[0,-0.065,0]}  fontSize={isMobile? 0.28 : 0.35 } font="./Fonts/Roboto.ttf" color={hoveredApply ? "#72c163" : "#ffffff"} anchorX="center" anchorY="bottom"
   textAlign='center'
    maxWidth={isMobile? radius* 15 :  15} >APPLY NOW
    </Text>
    <Cover scale={isMobile ? [1.3, 1.2, 1] : [1.6, 1.4, 1]}  />
    </group>


    <Text scale={0.3} position={isMobile? [0, -0.7 - fattore*6.3, -0.5] : [0, -6.4, -1]} strokeColor='#997ada' strokeOpacity={0.8} strokeWidth={0.007} fillOpacity={1} fontSize={isMobile? fattore*0.5: 1 } font="./Fonts/Roboto.ttf" color="#997ada" anchorX="center" anchorY="bottom"
   textAlign='left'
    maxWidth={isMobile? radius* 9 :  11} >ABOUT ARCA</Text>
    <Text scale={0.3} position={isMobile? [0, -0.7 - fattore*7.2, -0.5] : [0, -7.4, -1]} fontSize={isMobile? fattore*0.18: 0.35 } font="./Fonts/RobotoMedium.ttf" color="#ffffff" anchorX="center" anchorY="bottom"
   textAlign='left'
    maxWidth={isMobile? radius* 9 :  11} >ARCA is an innovative project across Bologna, Berlin, and Barcelona, focused on creating
    sustainable, non-binary fashion through a collaborative process, backed by specialised
    organisations in Italy, Spain, and Germany.
    This call for emerging designers aims to select three fashion designers residing in/nearby
    Bologna, Berlin, and Barcelona to develop a sustainable capsule collection inspired by
    these cities, offering mentorship and showcasing opportunities both online and offline
    </Text>


{/* <mesh position={isMobile? [0, -0.7 - fattore*6.01, -0.2] : [0, -0.8, -1]}  >
  <planeGeometry args={isMobile?[fattore*0.9,fattore*0.04]:[0, 0, 0]} />
  <meshBasicMaterial color='#997ada' transparent='true' opacity={0.4}  />
</mesh> */}


<group position={isMobile? [0,-fattore*0.1,0 ]:[0, 0, 0]} >
<group scale={isMobile? fattore*1.2:1} position={isMobile? [0, -0.7 - fattore*7.7, -0.6] : [0, -8.5, -0.51 ]} >
<ShaderOcean/> 
</group>

<Text rotation={[0, 0, 0]}
    strokeWidth={isMobile ? 0.01 : 0.04} strokeColor= "#ffffff" fillOpacity={0}
    scale={0.3} position={isMobile? [0, -0.7 - fattore*7.7, -0.5] : [0, -8.5, -0.5]}  fontSize={isMobile? fattore*0.8 : 1.4 } font="./Fonts/RobotoMedium.ttf" color="#ffffff" anchorX="center" anchorY="middle"
    maxWidth={isMobile? 20 :  20} >JOIN US!</Text>

</group>
{/* SESSIONE DOWNLOAD */}
<group position={isMobile? [0, -fattore*0.2, 0]:[0, 0, 0]} >
<Text scale={ 0.3} position={isMobile? [0, -0.7 - fattore*8.5, -0.5] : [0, -10, -1]} fontSize={isMobile?  fattore*0.6: 1 } strokeColor='#ffffff' strokeWidth={0.01} fillOpacity={1.0} font="./Fonts/Roboto.ttf" color="#ffffff" anchorX="center" anchorY="middle"
    maxWidth={isMobile? 3 :  8} >DOWNLOAD</Text>
   <Text scale={ 0.3} position={isMobile? [0, -0.7 - fattore*8.625, -0.5] : [0, -10.2, -1]} fontSize={isMobile?  fattore*0.24 : 0.4 } font="./Fonts/RobotoMedium.ttf" color="#ffffff" anchorX="center" anchorY="middle" fillOpacity={0.6}
     maxWidth={isMobile? 8 :  8} >Chose your language</Text>

{/* ENG */}
<group
onClick={() => window.open('https://drive.google.com/file/d/1tTTaxGU5aRzQNLQS9Xr6o3SUHD2ssUM_/view?usp=sharing', 'none')}
onPointerOver={() => setHovered(true)}
onPointerOut={() => setHovered(false)}
position={ isMobile? [0, -0.7 - fattore*8.9, 0] : [0, -8.2, 0]} scale={1.2} >
  <Text scale={ 0.3} position={isMobile? [0, -fattore*0.055, -0.5] : [0, -2.07, -1]} fontSize={isMobile? fattore*0.3 : 0.35 } font="./Fonts/Roboto.ttf" color={hovered ? "#72c163" : "#ffffff"} anchorX="center" anchorY="bottom"
   textAlign='center'maxWidth={isMobile? radius* 9 :  15} >ENG
    </Text>
    <Cover2 scale={isMobile ? [fattore*1.6, fattore*0.9, 1] : [4.5, 1.4, 1]} position={isMobile? [0, 0, -0.5] : [0, -2, -1]} />
 </group>

 <group onClick={() => window.open(' https://drive.google.com/file/d/1asFgqP8aUekx4T0vhdsnbOgS09HTbfQI/view?usp=sharing', 'none')}
    onPointerOver={() => setHovered2(true)} onPointerOut={() => setHovered2(false)} 
    position={isMobile? [0, -0.7- fattore*9.2, 0] : [0, -8.6, 0]} scale={1.2} >
    <Text scale={ 0.3} position={isMobile? [0, -fattore*0.055, -0.5] : [0, -2.07, -1]} fontSize={isMobile? fattore*0.3 : 0.35 } font="./Fonts/Roboto.ttf" color={hovered2 ? "#72c163" : "#ffffff"} anchorX="center" anchorY="bottom"
   textAlign='center' maxWidth={isMobile? radius* 9 :  15} >ITA
    </Text>
    <Cover2 scale={isMobile ? [fattore*1.6, fattore*0.9, 1] : [4.5, 1.4, 1]} position={isMobile? [0, 0, -0.5] : [0, -2, -1]} />
  </group>

  {/* SPA */}
  <group onClick={() => window.open('https://drive.google.com/file/d/1q3iHUHSU-f1vgKgJ_5xN-u8P-rX4nYrA/view?usp=sharing', 'none')}
     onPointerOver={() => setHovered3(true)}
    onPointerOut={() => setHovered3(false)}   position={isMobile? [0, -0.7- fattore*9.5, 0] : [0, -9, 0]} scale={1.2}>
     <Text scale={ 0.3} position={isMobile? [0, -fattore*0.055, -0.5] : [0, -2.07, -1]} fontSize={isMobile? fattore*0.3 : 0.35 } font="./Fonts/Roboto.ttf" color={hovered3 ? "#72c163" : "#ffffff"} anchorX="center" anchorY="bottom"
   textAlign='center' maxWidth={isMobile? radius* 9 :  15} >ESP
    </Text>
    <Cover2 scale={isMobile ? [fattore*1.6, fattore*0.9, 1] :[4.5, 1.4, 1]} position={isMobile? [0, -0, -0.5] : [0, -2, -1]} />
  </group>

{/* CAT */}
<group 
    onClick={() => window.open('https://drive.google.com/file/d/1huqyoEpPRJsloiRjaELW0rwl9bKZxSsu/view?usp=sharing', 'none')}
    onPointerOver={() => setHovered4(true)}
    onPointerOut={() => setHovered4(false)} position={isMobile? [0, -0.7- fattore*9.8, 0] : [0, -9.4, 0]} scale={1.2} >
   <Text scale={ 0.3} position={isMobile? [0, -fattore*0.055, -0.5] : [0, -2.07, -1]} fontSize={isMobile? fattore*0.3 : 0.35 } font="./Fonts/Roboto.ttf" color={hovered4 ? "#72c163" : "#ffffff"} anchorX="center" anchorY="bottom"
   textAlign='center' maxWidth={isMobile? radius* 9 :  15} >CAT
    </Text>
    <Cover2 scale={isMobile ? [fattore*1.6, fattore*0.9, 1] : [4.5, 1.4, 1]} position={isMobile? [0, 0, -0.5] : [0, -2, -1]} />
  </group>

     {/* DEU */}
     <group 
    onClick={() => window.open('https://drive.google.com/file/d/1s-3w3OFGhD2smV8HOiEDEjg-slXvnFMh/view?usp=sharing', 'none')}
    onPointerOver={() => setHovered6(true)}
    onPointerOut={() => setHovered6(false)} position={isMobile? [0, -0.7- fattore*10.1, 0] : [0, -9.8, 0]} scale={1.2}>
    <Text scale={ 0.3} position={isMobile? [0, -fattore*0.055, -0.5] : [0, -2.07, -1]} fontSize={isMobile? fattore*0.3 : 0.35 }font="./Fonts/Roboto.ttf" color={hovered6 ? "#72c163" : "#ffffff"} anchorX="center" anchorY="bottom"
   textAlign='center' maxWidth={isMobile? radius* 9 :  15} >DEU
    </Text>
    <Cover2 scale={isMobile ? [fattore*1.6, fattore*0.9, 1] :[4.5, 1.4, 1]} position={isMobile? [0, 0, -0.5] : [0, -2, -1]} />
  </group>
  </group>

<group  position={isMobile?[0, 0, 0]:[0, 0, 0]} >
  <group position={isMobile? [0, 0, 0] : [0, 0, 0]}  >

  <mesh position={isMobile? [0, -0.7-fattore*12, -0.6] : [0, -14.5, -1.2]}  scale={isMobile? 1:1} >
      <planeGeometry attach="geometry" args={isMobile? [12.181, fattore*2.2] :[12.181, 3]} />
      <meshBasicMaterial attach="material"  transparent={true} color="#ffffff"  emissiveIntensity={1}  />
    </mesh>

  <Text scale={0.3} position={isMobile? [0, -0.7 - fattore*11.9, -0.5] : [0, -14, -1]} fontSize={isMobile? fattore*0.2 : 0.35 } font="./Fonts/RobotoMedium.ttf" color="#333333" anchorX="center" anchorY="bottom"
   textAlign='left'
    maxWidth={isMobile? radius* 9 :  11} >ARCA is funded under the Creative Europe Programme and is promoted baumhaus (IT),
    specialising in innovative learning practices; @lantoki (SPA), a slow fashion SME offering
    online courses and digital patterns; and @circular.berlin (GER), a non-profit focused on
    circular economy, with a unit in Fashion and Textiles.
    </Text>
    <Text
    onPointerOver={() => setHovered5(true)}
    onPointerOut={() => setHovered5(false)}
    onClick={() => window.location.href = "mailto:arcapparel@baumhaus.bo.it"}
    scale={hovered5? 0.32 : 0.3} position={isMobile? [0, -0.7 - fattore*12.2, -0.5] : [0, -14.2, -1]} fontSize={isMobile? fattore*0.2 : 0.35 } font="./Fonts/RobotoMedium.ttf" color={hovered5 ? "#43659c" : "#333333"} anchorX="center" anchorY="bottom"
   textAlign='left'
    maxWidth={isMobile? radius* 9 :  11} >For more information, contact us at arcapparel@baumhaus.bo.it
    </Text>

    <mesh position={isMobile? [0, -0.7 - fattore*12.6, -0.5] :[0, -14.8, -1]} scale={isMobile? fattore*0.9 : 2.2} >
      <planeGeometry attach="geometry" args={[0.512, 0.215]} />
      <meshBasicMaterial attach="material"  map={texturecircular} transparent={true} />
    </mesh>

    <mesh position={isMobile? [-fattore*0.38, -0.7 - fattore*12.6, -0.5] : [-1.2, -14.8, -1]} scale={isMobile? fattore*0.4 : 1.2} >
      <planeGeometry attach="geometry" args={[0.512, 0.512]} />
      <meshBasicMaterial attach="material"  map={texturebh} transparent={true} />
    </mesh>  

    <mesh position={isMobile? [fattore*0.38, -0.7 - fattore*12.6, -0.5] :[1.2, -14.8, -1]} scale={isMobile? fattore*0.5: 1.2} >
      <planeGeometry attach="geometry" args={[0.470, 0.626]} />
      <meshBasicMaterial attach="material"  map={texturelantoki} transparent={true} />
    </mesh>

    <mesh position={isMobile? [fattore*0.3, -0.7 - fattore*13, -0.5] : [radius + 0.6, -15.5, -1]} scale={isMobile? fattore*0.1: 0.2} >
      <planeGeometry attach="geometry" args={[4.119, 0.919]} />
      <meshStandardMaterial attach="material" color="#000000"  map={texture3} transparent={true} />
    </mesh>
    
  <Text scale={0.3} position={isMobile? [-fattore*0.3, -0.7 - fattore*13.02, -0.5] : [0, -15.7, -1]} fontSize={isMobile? fattore*0.1 : 0.2 } font="./Fonts/RobotoMedium.ttf" color="#333333" anchorX="center" anchorY="bottom"
   textAlign='left'
    maxWidth={isMobile? radius* 9 :  11} >Project 101131677 — ARCA
    </Text>
   </group>
  </group>
</group>
</group>
{/* GRUPPO FOOTER */}
 
</Scroll>

</>
)}




function TestoScroll() {
  const isClient = typeof window !== "undefined";
  const isMobile = isClient ? window.innerWidth <= 1200 : false;
  const viewport = useThree((state) => state.viewport)
  const radius = Math.min(2.4, viewport.width / 2.8);
  const w =  viewport.width *1.18
  const [texture, texture2, ] = useLoader(TextureLoader, ['logo.png','logos.png']);
  // FUNZIONE TASTO MENU
  const menuGroupRef = useRef();
  const toggleMenu = () => {
    const currentPosition = menuGroupRef.current.position;
    const targetPosition = currentPosition.equals(new THREE.Vector3(0, 0, 0)) ? new THREE.Vector3(0, 8, 0) : new THREE.Vector3(0, 0, 0);
    if (!currentPosition.equals(targetPosition)) {
      gsap.to(menuGroupRef.current.position, { x: targetPosition.x, y: targetPosition.y, z: targetPosition.z, duration: 0.75,ease: "power3.easeInOut" }); }};
  // HOVERED BOTTONI 
    const ScrollableText = () => {

    
    const [hoveredhome, setHoveredhome] = useState(false);
    const [hoveredhome2, setHoveredhome2] = useState(false);
    const [hoveredabout, setHoveredabout] = useState(false);
    const [hovereddownload, setHovereddownload] = useState(false);
    const [hoveredcontacts, setHoveredcontacts] = useState(false);
  // FUNZIONI SCROLLMAP 
    const scroll = useScroll();
    const navigateToPercentage = (percentage) => {
      const scrollElement = scroll.el;
      if (scrollElement) {
        const totalScrollHeight = scrollElement.scrollHeight - scrollElement.clientHeight;
        const newScrollPosition = totalScrollHeight * (percentage / 100);
        scrollElement.scrollTop = newScrollPosition;
      }
    };
    
    const NavHome = () => navigateToPercentage(0); // Vai all'inizio
    const NavAbout = () => navigateToPercentage(isMobile? 58:58); // Esempio: Vai al 20% della lunghezza totale scrollabile
    const NavDownload = () => navigateToPercentage(isMobile?75:81); // Esempio: Vai al 50%
    const NavContacts = () => navigateToPercentage(96); // Esempio: Vai all'80%
    
    return (
      <>


    {/* TASTO MENU */}
    <group scale={isMobile ? 0.8 : 0.8} position={isMobile ? [radius * 1.5 - 0.28, 0.22, 0] : [radius * 1.5 - 0.5, 0.215, 0]}>
     <Text onClick={toggleMenu} onPointerOver={() => setHoveredhome(true)} onPointerOut={() => setHoveredhome(false)} scale={0.5} position={[0, 1.22, 0.001]} fontSize={isMobile ? 0.24 : 0.35} font="sans.ttf" color={hoveredhome ? "#72c163" : "#ffffff"} anchorX="center" anchorY="middle">MENU</Text>
     <Cover scale={isMobile ? [1, 1, 1] : [1.5, 1.2, 1]} position={[0, 1.22, 0]} />
    </group>
    {/* COVER TOP */}
    {/* <mesh position={isMobile? [0.1, 1.3, 0] : [0.1, 1.33, -0.1]} rotation={[0, 0, 0]}><planeGeometry attach="geometry" args={isMobile? [10, 0.5] : [10, 0.5]} /><meshStandardMaterial color="#1b1525" attach="material" /></mesh> */}
    {/* MENU */}
    <group position={[0, 10, 0]} ref={menuGroupRef}>
   
        <group scale={isMobile ? 0.5 : 1} position={isMobile ? [-0.56, -0.2, 0] : [-2.5, -0.65, 0]}>
            <Text onClick={() => window.location.href = "https://www.arcapparel.eu"}  onPointerOver={() => setHoveredhome2(true)} onPointerOut={() => setHoveredhome2(false)}  strokeWidth={isMobile ? 0.005 : 0.03} strokeColor={hoveredhome2 ? "#72c163" : "#ffffff"} fillOpacity={hoveredhome2 ? 0 : 1} scale={0.5} position={isMobile ? [0, 1.2, 0] : [-radius + 2.2, 1.2, 0]} fontSize={isMobile ? 0.8 : 0.8} font="sans.ttf" lineHeight={0.7} color="#ffffff" anchorX="left" anchorY="middle">HOME</Text>
            <Text onClick={() => { NavAbout(); toggleMenu(); }} onPointerOver={() => setHoveredabout(true)}onPointerOut={() => setHoveredabout(false)} strokeWidth={isMobile ? 0.005 : 0.03} strokeColor= {hoveredabout ? "#72c163" : "#ffffff"} fillOpacity={hoveredabout ? 0 : 1} scale={0.5} position={isMobile ? [0 , 0.7, 0] : [-radius + 2.2, 0.8, 0]} fontSize={isMobile ? 0.8 : 0.8} font="sans.ttf" lineHeight={0.7} color="#ffffff" anchorX="left" anchorY="middle">ABOUT</Text>
            <Text onClick={() => { NavDownload(); toggleMenu(); }} onPointerOver={() => setHovereddownload(true)} onPointerOut={() => setHovereddownload(false)} strokeWidth={isMobile ? 0.005 : 0.03} strokeColor={hovereddownload ? "#72c163" : "#ffffff"} fillOpacity={hovereddownload ? 0 : 1} scale={0.5} position={isMobile ? [0, 0.2, 0] : [-radius + 2.2, 0.4, 0]} fontSize={isMobile ? 0.8 : 0.8} font="sans.ttf" lineHeight={0.7} color="#ffffff" anchorX="left" anchorY="middle">DOWNLOAD</Text>
            <Text onClick={() => { NavContacts(); toggleMenu(); }} onPointerOver={() => setHoveredcontacts(true)} onPointerOut={() => setHoveredcontacts(false)} strokeWidth={isMobile ? 0.005 : 0.03} strokeColor={hoveredcontacts ? "#72c163" : "#ffffff"} fillOpacity={hoveredcontacts ? 0 : 1} scale={0.5} position={isMobile ? [0, -0.3, 0] : [-radius + 2.2, 0, 0]} fontSize={isMobile ? 0.8 : 0.8} font="sans.ttf" lineHeight={0.7} color="#ffffff" anchorX="left" anchorY="middle">CONTACTS</Text>
        </group>
        <mesh position={isMobile ? [0.1, 0, -0.1] : [0, 1.35, -0.1]} rotation={[0, 0, 0]}>
          <planeGeometry attach="geometry" args={isMobile ? [10, 10] : [10, 10]} /> <meshStandardMaterial color="#1b1525" attach="material" />
        </mesh>
        <group scale={isMobile ? 0.15 : 0.35} position={isMobile ? [-0.3, -0.8, -0.1] : [-1, 0, -0.1]}>
            <mesh position={[4, -2, 0.2]} scale={15}>
           <planeGeometry attach="geometry" args={[1.181, 0.827]} /> <meshStandardMaterial attach="material" map={texture2} transparent={true} opacity={0.3} color="#43659c" emissive='#43659c' emissiveIntensity={1} />
            </mesh>
        </group>
    </group>
</>
    );
  };

  return (
    <ScrollControls distance={1.2} damping={0.1} pages={isMobile ? 5.52 +Math.pow(w,2 ) : 7.3}>
     <ScrollableText />
     <Items />
    </ScrollControls>
  );
}


export function Line(props) {
  const points = [];
    points.push(new THREE.Vector3(-5, 0, 0));
    points.push(new THREE.Vector3(0, 0, 0));
    points.push(new THREE.Vector3(5, 0, 0));
    const lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
    const lineMaterial = new THREE.LineBasicMaterial({ color: '#ffffff', transparent:true, opacity:0.4 });
    return (
        <line {...props} geometry={lineGeometry} material={lineMaterial} />
    );
  }
  


  export function Cover(props) {
    const [texture10] = useLoader(TextureLoader, [
      'cornice.png'  
    ]);
    
    return (
      <group {...props} dispose={null}>
        <mesh >
        <planeGeometry args={ [0.450, 0.220]} />
        <meshBasicMaterial color= '#72c163' transparent={true} map={texture10}  />
        </mesh>
     </group>
    );
  }
  export function Cover2(props) {
    const [texture10] = useLoader(TextureLoader, [
      'cornice.png'  
    ]);
    
    return (
      <group {...props} dispose={null}>
        <mesh >
        <planeGeometry args={ [0.450, 0.220]} />
        <meshBasicMaterial color= '#997ada' transparent={true} map={texture10}  />
        </mesh>
     </group>
    );
  }
  
