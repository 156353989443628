import { Canvas, useThree } from '@react-three/fiber';
import { Suspense, useState, useEffect } from 'react';
import { gsap } from 'gsap';
import App from './App';
import Cursor from './Cursor';



export default function Render() {


  return (
    <div className="canvas-container">
      <Cursor />
      <Canvas camera={{ fov: 25, position: [0, 0, 6] }} linear={true}>
        {/* <fog attach="fog" args={['#181820', 0, 100]} /> */}
        
         
          <App />
      
      </Canvas>
    </div>
  );
}



